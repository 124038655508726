/* autoprefixer grid: autoplace */
@import "../../../styles/colors.scss";

.LepoRow {
  display: grid;
  grid-template-columns: 3.5em 1fr 3.5em;

  .row {
    // add padding from dates to height (3.5 + 0.3 + 0.3)
    height: 4.1em;
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));

    .venueSwitch {
      position: relative;
      height: 100%;
      width: 100%;
      overflow: hidden;
      // white-space: nowrap;

      .venueContainer {
        height: 100%;

        transition: transform 0.3s cubic-bezier(0.26, 0.86, 0.44, 0.985);
        // grid-template-columns: 1fr;

        &.size-2 {
          display: grid;
          // display: none;
          width: 200%;
          grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
        }

        &.size-3 {
          display: grid;
          // display: none;
          width: 300%;
          grid-template-columns: repeat(3, minmax(0, 1fr));
        }

        &.size-4 {
          display: grid;
          // display: none;
          width: 400%;
          grid-template-columns: repeat(4, minmax(0, 1fr));
        }

        & > div {
          position: relative;
          height: 100%;
          // display: inline-block;

          margin-left: 0.3em;
        }
      }

      &.active-1 {
        .venueContainer.size-2 {
          transform: translateX(-50%);
        }

        .venueContainer.size-3 {
          transform: translateX(-33.33%);
        }

        .venueContainer.size-4 {
          transform: translateX(-25%);
        }
      }

      &.active-2 {
        .venueContainer.size-3 {
          transform: translateX(-66.66%);
        }

        .venueContainer.size-4 {
          transform: translateX(-50%);
        }
      }

      &.active-3 {
        .venueContainer.size-4 {
          transform: translateX(-75%);
        }
      }
    }
  }

  .emptyDate {
    height: 3.5em;
    padding: 0.3em 0;
    width: 100%;
    padding-left: 0;

    .weekday {
      line-height: 3.5em;
      text-align: center;
      font-family: "Egyptienne";
      font-weight: bold;
      text-transform: uppercase;
    }
  }

  .events {
    position: relative;
    margin-right: 0.3em;
  }

  &.even:not(.today) {
    .rightAligned {
      // border: 1px solid red;
      background-color: #fff;
      background: linear-gradient(
        to right,
        rgba(255, 255, 255, 0) 0%,
        #fff 1em
      );
    }

    .grosses-haus {
      background-color: #fff;
      color: $grosses-haus;
    }

    .dates.grosses-haus > *:nth-child(2n),
    .row:nth-child(2n) .grosses-haus {
      background-color: $grosses-haus;
      color: #fff;

      .rightAligned {
        background-color: $grosses-haus;
        background: linear-gradient(
          to right,
          $grosses-haus-opaque 0%,
          $grosses-haus 1em
        );
      }
    }

    .roter-salon {
      background-color: #fff;
      color: $roter-salon;
    }

    .dates.roter-salon > *:nth-child(2n),
    .row:nth-child(2n) .roter-salon {
      background-color: $roter-salon;
      color: #fff;

      .rightAligned {
        background-color: $roter-salon;
        background: linear-gradient(
          to right,
          $roter-salon-opaque 0%,
          $roter-salon 1em
        );
      }
    }

    .gruener-salon {
      background-color: #fff;
      color: $gruener-salon;
    }

    .dates.gruener-salon > *:nth-child(2n),
    .row:nth-child(2n) .gruener-salon {
      background-color: $gruener-salon;
      color: #fff;

      .rightAligned {
        background-color: $gruener-salon;
        background: linear-gradient(
          to right,
          $gruener-salon-opaque 0%,
          $gruener-salon 1em
        );
      }
    }

    .specialInfo {
      background-color: #000;
      color: #fff;
    }

    .row:nth-child(2n) .specialInfo {
      background-color: #fff;
      color: #000;
    }

    &.special,
    &.mobile {
      .itemContainer,
      .dates {
        background-color: #fff;
        color: #000;
      }

      .dates > *:nth-child(2n),
      .row:nth-child(2n) .itemContainer {
        background-color: #000;
        color: #fff;

        .rightAligned {
          background-color: #000;
          background: linear-gradient(to right, rgba(0, 0, 0, 0) 0%, #000 1em);
        }
      }
    }

    &.festival {
      .itemContainer,
      .dates {
        background-color: #fff;
        color: $current-day;
      }

      .dates > *:nth-child(2n),
      .row:nth-child(2n) .itemContainer {
        background-color: $current-day;
        color: #fff;

        .rightAligned {
          background-color: $current-day;
          background: linear-gradient(
            to right,
            rgba(0, 0, 0, 0) 0%,
            $current-day 1em
          );
        }
      }
    }
  }

  &.odd:not(.today) {
    .grosses-haus {
      background-color: $grosses-haus;
      color: #fff;

      .rightAligned {
        background-color: $grosses-haus;
        background: linear-gradient(
          to right,
          $grosses-haus-opaque 0%,
          $grosses-haus 1em
        );
      }
    }

    .dates.grosses-haus > *:nth-child(2n),
    .row:nth-child(2n) .grosses-haus {
      background-color: #fff;
      color: $grosses-haus;

      .rightAligned {
        background-color: #fff;
        background: linear-gradient(
          to right,
          rgba(255, 255, 255, 0) 0%,
          #fff 1em
        );
      }
    }

    .roter-salon {
      background-color: $roter-salon;
      color: #fff;

      .rightAligned {
        background-color: $roter-salon;
        background: linear-gradient(
          to right,
          $roter-salon-opaque 0%,
          $roter-salon 1em
        );
      }
    }

    .dates.roter-salon > *:nth-child(2n),
    .row:nth-child(2n) .roter-salon {
      background-color: #fff;
      color: $roter-salon;

      .rightAligned {
        background-color: #fff;
        background: linear-gradient(
          to right,
          rgba(255, 255, 255, 0) 0%,
          #fff 1em
        );
      }
    }

    .gruener-salon {
      background-color: $gruener-salon;
      color: #fff;

      .rightAligned {
        background-color: $gruener-salon;
        background: linear-gradient(
          to right,
          $gruener-salon-opaque 0%,
          $gruener-salon 1em
        );
      }
    }

    .dates.gruener-salon > *:nth-child(2n),
    .row:nth-child(2n) .gruener-salon {
      background-color: #fff;
      color: $gruener-salon;

      .rightAligned {
        background-color: #fff;
        background: linear-gradient(
          to right,
          rgba(255, 255, 255, 0) 0%,
          #fff 1em
        );
      }
    }

    .specialInfo {
      background-color: #fff;
      color: #000;
    }

    .row:nth-child(2n) .specialInfo {
      background-color: #000;
      color: #fff;
    }

    &.special,
    &.mobile {
      .itemContainer,
      .dates {
        background-color: #000;

        .rightAligned {
          background-color: #000;
          background: linear-gradient(to right, rgba(0, 0, 0, 0) 0%, #000 1em);
        }
      }

      .dates > *:nth-child(2n),
      .row:nth-child(2n) .itemContainer {
        background-color: #fff;
        color: #000;

        .rightAligned {
          background-color: #fff;
          background: linear-gradient(
            to right,
            rgba(255, 255, 255, 0) 0%,
            #fff 1em
          );
        }
      }
    }

    &.festival {
      .itemContainer,
      .dates {
        background-color: $current-day;

        .rightAligned {
          background-color: $current-day;
          background: linear-gradient(
            to right,
            rgba(0, 0, 0, 0) 0%,
            $current-day 1em
          );
        }
      }

      .dates > *:nth-child(2n),
      .row:nth-child(2n) .itemContainer {
        background-color: #fff;
        color: $current-day;

        .rightAligned {
          background-color: #fff;
          background: linear-gradient(
            to right,
            rgba(255, 255, 255, 0) 0%,
            #fff 1em
          );
        }
      }
    }
  }

  &.today {
    &.odd .dates {
      background-color: $current-day;
      color: #fff;

      & > *:nth-child(2n) {
        background-color: #fff;
        color: $current-day;
      }
    }

    &.even .dates {
      background-color: #fff;
      color: $current-day;

      & > *:nth-child(2n) {
        background-color: $current-day;
        color: #fff;
      }
    }

    &.odd .itemContainer,
    &.even .row:nth-child(2n) .itemContainer {
      background-color: $current-day;
      color: #fff;

      .specialInfo {
        background-color: #fff;
        color: $current-day;
      }

      .rightAligned {
        background-color: $current-day;
        background: linear-gradient(
          to right,
          $current-day-opaque 0%,
          $current-day 1em
        );
      }
    }

    &.even .itemContainer,
    &.odd .row:nth-child(2n) .itemContainer {
      background-color: #fff;
      color: $current-day;

      .specialInfo {
        background-color: $current-day;
        color: #fff;
      }

      .rightAligned {
        background-color: #fff;
        background: linear-gradient(
          to right,
          rgba(255, 255, 255, 0) 0%,
          #fff 1em
        );
      }
    }
  }
}

@media (max-width: 850px) {
  .LepoRow {
    // height: 5em;
    // position: relative;
    grid-template-columns: 3.5em 1fr;

    // * {
    //   // outline: 1px solid red;
    // }

    // .events {
    //   position: relative;
    //   display: block;
    //   // display: flex;

    //   // width: 100%;
    //   height: 100%;
    //   overflow: hidden;
    // }

    // .dates {
    //   height: 5em;
    //   padding: 0;
    // }

    .row {
      height: 4.9em;
      position: relative;
      display: block;
    }

    .emptyDate {
      height: 4.3em;

      .weekday {
        line-height: 4.3em;
      }
    }
    // width: 400%;

    //   // add padding from dates to height (3.5 + 0.3 + 0.3)
    //   // position: relative;
    //   height: 4.1em;
    //   white-space: nowrap;
    //   // transform: translateX(-75%);

    //   // overflow: hidden;
    //   // grid-template-columns: minmax(0, 2fr) minmax(0, 1fr) minmax(0, 1fr);

    //   .allVenues {
    //     position: relative;
    //     display: grid;
    //     grid-template-columns: repeat(4, minmax(0, 1fr));
    //     width: 100%;
    //     // overflow: hidden;
    //     // border: 1px solid green;
    //   }

    //   .venue {
    //     position: relative;
    //     display: inline-block;
    //     vertical-align: top;
    //     // display: block;
    //     // flex: 0;
    //     height: 100%;
    //     width: 100%;
    //   }
    // }
    .events {
      position: relative;
      margin-right: 0;
    }

    .dates.end {
      display: none;
    }
  }
}
