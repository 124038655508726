/* autoprefixer grid: autoplace */
@import "../../styles/colors.scss";
@import "../../styles/fonts.css";

#mainNav {
  ul.mainMenu {
    flex: 2 0 auto;
    text-align: right;
    font-size: 1.4em;
    margin: 0;
    // padding-bottom: 0.2em;
    // position: fixed;
    // top: 1em;
    // right: 2em;
    // a:not(.searchResult),

    li {
      list-style-type: none;
      display: inline-block;
    }

    a,
    button {
      padding: 0;
      // font-size: 1.2em;
      font-weight: bold;
      color: #000;
      text-decoration: none;
      text-transform: uppercase;

      &.active {
        color: $activeLink;
      }

      &.menuPoint {
        margin-left: 0.5em;
      }

      &.lang-option {
        &::after {
          content: "/";
          color: black;
        }

        &:last-child::after {
          display: none;
        }
      }
    }
  }

  button {
    font-family: inherit;
    font-size: inherit;
    outline: none;
    border: none;
    background-color: transparent;
    text-transform: uppercase;
    cursor: pointer;
  }
}

@media (max-width: 1024px) {
  #mainNav {
    ul.mainMenu {
      a,
      button {
        font-size: 0.9em;
      }
    }
  }
}

@media screen and (max-width: 850px) {
  #mainNav {
    font-family: "Egyptienne";
    font-size: 1.2em;

    ul.mainMenu {
      padding: 0;
      margin-top: 1em;

      li {
        display: grid;
        grid-template-columns: 1.2em 1fr;
        justify-items: flex-start;
      }

      .angle {
        color: #fff;
        width: 1em;
        height: 1em;
        line-height: 1em;
        text-align: center;
        transform: rotate(0deg);
        transform-origin: center center;
        transition: transform 0.3s cubic-bezier(0.26, 0.86, 0.44, 0.985);
        &.active {
          transform: rotate(90deg);
        }
      }

      a,
      button {
        display: block;
        color: #fff;
        text-transform: none;
        margin-top: -0.1em;
        margin-bottom: 0.2em;

        &.menuPoint {
          margin-left: 0;
        }
      }
    }
  }
  // .mainMenu {
  //   position: absolute;
  //   z-index: 200;
  //   top: 0;
  //   left: 0;
  //   height: 100%;
  //   width: 100%;
  //   background-color: #000;
  // }
}
