@import "../../../styles/colors.scss";
@import "../../../styles/fonts.css";

.productionTitle {
  display: block;
  top: -1px;
  margin-top: -1px;
  z-index: 100;
  font-family: "Goudy";
  color: #fff;
  text-align: left;
  padding: 1em 1.5em;

  &.default {
    background-color: $navbg;
  }

  &.grosses-haus {
    background-color: $grosses-haus;
  }

  &.roter-salon {
    background-color: $roter-salon;
  }

  &.gruener-salon {
    background-color: $gruener-salon;
  }

  &.festival {
    background-color: $current-day;
  }

  h1,
  h2 {
    margin: 0;
  }

  h1 {
    line-height: 1;
  }

  .titleInfos *:first-child .divider {
    display: none;
  }

  .titleInfos {
    font-size: 1em;

    h2 {
      display: inline;
      font-size: 1em;
    }
  }

  @media screen and (max-width: 450px) {
    h1 {
      font-size: 1.6em;
    }

    .titleInfos {
      font-size: 0.9em;
    }
  }
}
