/*
  This CSS resource incorporates links to font software which is the valuable copyrighted property of Monotype and/or its suppliers. You may not attempt to copy, install, redistribute, convert, modify or reverse engineer this font software. Please contact Monotype with any questions regarding Web Fonts:  https://www.linotype.com
*/

@import url("https://fast.fonts.net/lt/1.css?apiType=css&c=1b0eccc0-ddeb-40b9-ba15-b19f3e587571&fontids=5121402,5188796,5167734,5178591,5309226,5377642,5740224,5741404,5743732");
@import url("https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@500;900&subset=cyrillic");
/* deactivated: 923621 (Egyptienne Extra Narrow) */

/* 5377642 */
@font-face {
  font-family: "Egyptienne";
  src: url("../fonts/egyptienne-medium/57d22a61-b51c-4689-9fad-7b6fee8bb806.woff2")
      format("woff2"),
    url("../fonts/egyptienne-medium/f7a6dc00-49ad-420c-b6fc-9797366d4e84.woff")
      format("woff");
  font-weight: normal;
}
@font-face {
  font-family: "Egyptienne";
  src: url("../fonts/5121402/c4f9ad7d-a83c-467c-9a9a-5e526290a6ab.woff2")
      format("woff2"),
    url("../fonts/5121402/a0074169-2394-499b-bc44-91d3d64b755c.woff")
      format("woff");
  font-weight: bold;
}
@font-face {
  font-family: "Egyptienne Narrow";
  src: url("../fonts/5167734/8658ff65-ef0b-4bdb-85aa-a2d865c98a51.woff2")
      format("woff2"),
    url("../fonts/5167734/ca76ca6a-5984-420d-98fa-a75062a272b5.woff")
      format("woff");
  font-weight: normal;
}
@font-face {
  font-family: "Egyptienne Narrow";
  src: url("../fonts/5178591/d001b38c-e82f-4d95-8b6a-22cdf2f5ebf6.woff2")
      format("woff2"),
    url("../fonts/5178591/d0143712-6657-44b6-a0c1-69d821888824.woff")
      format("woff");
  font-weight: bold;
}

/* @font-face {
  font-family: "Egyptienne Extra Narrow";
  src: url("../fonts/923621/5c10e834-cfa4-41d4-baf3-a19985e0a65b.woff2")
      format("woff2"),
    url("../fonts/923621/971560ec-e802-4099-88ed-d5339882fe4b.woff")
      format("woff");
  font-weight: bold;
} */

/* 5309226 */
@font-face {
  font-family: "Egyptienne Extrawide";
  src: url("../fonts/egyptienne-xw-bold/51907ac5-ae58-45a5-8905-1b761a9bf997.woff2")
      format("woff2"),
    url("../fonts/egyptienne-xw-bold/f47c2f4a-47dd-4201-9674-17286ddfd004.woff")
      format("woff");
  font-weight: bold;
}
@font-face {
  font-family: "Goudy";
  src: url("../fonts/5188796/5fbd521c-7b7f-462c-b301-3f5623c091f3.woff2")
      format("woff2"),
    url("../fonts/5188796/b6c26940-ea97-4331-87c3-e98fcf44d44a.woff")
      format("woff");

  font-weight: bold;
}
@font-face {
  font-family: "Franklin Gothic";
  src: url("../fonts/5740224/f251f0e9-1ff3-4670-ad23-f49bed794adf.woff2")
      format("woff2"),
    url("../fonts/5740224/4a6e59b3-2cae-4ac0-acd0-1e44102cf691.woff")
      format("woff");
  font-weight: 500;
}
@font-face {
  font-family: "Franklin Gothic";
  src: url("../fonts/5741404/bec00074-1e9d-4885-9f43-4ca5f134a801.woff2")
      format("woff2"),
    url("../fonts/5741404/59cf6fa8-1f04-4b2b-8a2c-a12fd2d78152.woff")
      format("woff");
  font-weight: normal;
}

@font-face {
  font-family: "Franklin Gothic Condensed";
  src: url("../fonts/5743732/9d28a68b-4835-466b-81cc-68c579f043ce.woff2")
      format("woff2"),
    url("../fonts/5743732/c5032178-d765-4f39-a4f6-65e0085b494d.woff")
      format("woff");
  font-weight: normal;
}
