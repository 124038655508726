@import "../../styles/colors.scss";

.VideoPlayer {
  position: relative;
  // height: 100%;

  &.other {
    height: 100%;
  }

  video {
    width: auto;
    height: 100%;
    background-color: #000;
  }
}

.page .VideoPlayer {
  background-color: #000;
}

.buttonContainer {
  position: absolute;
  z-index: 100;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  .playPause {
    position: absolute;
    opacity: 0;
    width: 5em;
    height: 5em;
    background-color: rgba(255, 255, 255, 0.4);
    border-radius: 50%;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
    transition: 0.3s linear opacity 0.5s;
    cursor: pointer;

    .MuiSvgIcon-root {
      font-size: 5em;
      color: #fff;
    }

    &:hover .MuiSvgIcon-root {
      color: $activeLink;
    }
  }

  .playPause.show,
  .playPause.touch,
  .playPause.mobile-false:hover {
    opacity: 1;
    transition-delay: 0s;
  }

  .closeVideo {
    position: absolute;
    color: #fff;
    z-index: 100;
    top: 1em;
    right: 1em;
    cursor: pointer;
  }

  // .playPause.notouch,
  // &:hover .playPause {
  //   opacity: 0;
  // }
}

@media (max-aspect-ratio: 1/1), (max-width: 850px) {
  .VideoPlayer {
    video {
      width: 100%;
      height: auto;
    }
  }

  .page .VideoPlayer {
    background-color: transparent;
  }
}

@media (max-width: 600px) {
  .VideoPlayer {
    .playPause {
      width: 3em;
      height: 3em;

      .MuiSvgIcon-root {
        font-size: 3em;
      }
    }
  }
}
