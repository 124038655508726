$grosses-haus: #00acff;
$prater: #f08700;
$dritter-stock: #00b900;
$roter-salon: #ff0000;
$gruener-salon: #00b900;
$zirkuszelt: #00b900;
$rosa-luxemburg-platz: #00b900;
$pavillon: #00b900;
$sternschuppen: #00b900;
$navbg: #000; //#f3a9c5;
$current-day: #ff8c30;

$activeLink: #e31c18;
$background: #d2d2d2;
$gray: #d2d2d2;
$dark-gray: darkgray;
$blue: #1c4e9c;

$grosses-haus-opaque: rgba(19, 137, 175, 0);
$prater-opaque: rgba(240, 135, 0, 0);
$dritter-stock-opaque: rgba(19, 137, 175, 0);
$roter-salon-opaque: rgba(227, 28, 24, 0);
$gruener-salon-opaque: rgba(82, 174, 50, 0);
$zirkuszelt-opaque: #52ae3200;
$rosa-luxemburg-platz-opaque: rgba(82, 174, 50, 0);
$pavillon-opaque: rgba(82, 174, 50, 0);
$sternschuppen-opaque: rgba(82, 174, 50, 0);
$dark-gray-opaque: rgba(169, 169, 169, 0);
$current-day-opaque: rgba(255, 140, 48, 0);
