/* autoprefixer grid: autoplace */
@import "../../styles/colors.scss";
@import "../../styles/fonts.css";

main.show {
  text-align: left;

  .topImage {
    position: relative;
    width: 100%;
    // background-color: #000;
    text-align: center;
    margin-bottom: 0.3em;
  }

  .titleSection {
    display: block;
    // position: sticky;
    // position: -webkit-sticky;
    z-index: 100;
    font-family: "Goudy", "Roboto Slab";
    color: #fff;
    text-align: left;
    padding: 1em 1.5em;

    h1,
    h2 {
      margin: 0;
    }

    h1 {
      line-height: 1;

      .divider:last-child {
        display: none;
      }
    }

    .titleInfos *:first-child .divider:first-child {
      display: none;
    }

    .titleInfos {
      font-size: 1em;

      h2 {
        display: inline;
        font-size: 1em;
      }
    }
  }

  &.grosses-haus {
    .titleSection,
    .topImage {
      background-color: $grosses-haus;
    }
  }

  &.roter-salon {
    .titleSection,
    .topImage {
      background-color: $roter-salon;
    }
  }

  &.gruener-salon {
    .titleSection,
    .topImage {
      background-color: $gruener-salon;
    }
  }

  &.festival {
    .titleSection,
    .topImage {
      background-color: $current-day;
    }
  }

  section {
    position: relative;
    display: block;
    // grid-template-columns: 1fr 30em;
    font-family: "Egyptienne Narrow", "Roboto Slab";
    padding: 1.5em 0;

    &.calendar {
      padding: 0;
    }

    .calendarWrapper {
      display: flex;
      flex-direction: column;
      width: 40%;
      float: right;
      min-width: none;
      // position: sticky;
      // position: -webkit-sticky;
      justify-content: flex-end;
      margin-bottom: 3em;

      .separator {
        display: none;
        font-size: 1.2em;
        line-height: 1.4;
        margin-top: 1.7rem;
        margin-bottom: 1.7rem;
        border-bottom: 3px solid black;
      }

      .linkBack {
        display: block;
        font-size: 1.2em;
        margin-top: 1em;

        &:first-child {
          margin-top: 0;
        }
      }
    }

    .descriptionWrapper {
      vertical-align: text-top;
      display: inline-block;
      width: 60%;
      padding-right: 2em;
      padding-left: 2px;
      box-sizing: border-box;
      font-size: 1.2em;
      margin-bottom: 2em;
      word-wrap: break-word;
      // max-width: 30em;
      // margin-right: 2em;

      p {
        margin-top: 1em;
      }

      p:first-of-type {
        margin-top: 0em;
      }

      p.additionalInfo {
        margin-top: 1em;
      }
    }

    .description {
      a {
        text-decoration: underline;
      }

      a[href^="tel:"] {
        text-decoration: none;
      }
    }

    .description > div {
      border-top: 3px solid black;
      padding-top: 1.5rem;
      margin-top: 1.5rem;

      &:empty,
      &:first-child {
        border: none;
        padding-top: 0em;
        margin-top: 0em;
      }
    }

    .description .MediaCredits {
      border-top: 3px solid black;
      padding-top: 1.5rem;
      margin-top: 1.5rem;
    }

    .jobtype {
      &.addMargin {
        margin-bottom: 1em;
      }

      &:last-child {
        margin-bottom: 0;
      }

      h4 {
        display: inline;
        // font-weight: normal;
      }

      .person .nobreak {
        white-space: nowrap;
      }

      .person:last-child {
        .separator {
          display: none;
        }
      }
    }
  }

  section + .separator {
    display: block !important;
  }
}

@media (max-width: 850px) {
  main.show {
    .titleSection {
      font-size: 0.7em;
      // position: sticky;
      // position: -webkit-sticky;
    }

    section {
      grid-template-columns: 1fr;

      .calendarWrapper {
        display: block;
        width: 100%;

        .linkBack {
          font-size: 1em;
        }
      }

      .descriptionWrapper {
        display: block;
        width: 100%;
        // position: relative;
        // overflow: hidden;
        // overflow: hidden;
        // text-overflow: ellipsis;
        // min-height: 5em;
        padding-right: 0;
        font-size: 1em;
        // transition: min-height 2s cubic-bezier(0.26, 0.86, 0.44, 0.985);

        // &.hide {
        //   display: -webkit-box;
        //   -webkit-line-clamp: 7;
        //   -webkit-box-orient: vertical;
        //   // min-height: 10em;
        // }

        .description {
          padding-bottom: 0.2em;
        }

        .showMore {
          position: absolute;
          width: 100%;
          bottom: 0;
          padding-top: 0.2em;
          background-color: #fff;
          cursor: pointer;
        }

        .person .nobreak {
          white-space: normal;
        }
      }
    }
  }
}
