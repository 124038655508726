/* autoprefixer grid: autoplace */
@import "../../styles/colors.scss";
.EventCalendar {
  top: 1.5em;
  // flex: 1 0 23em;
  // justify-self: flex-end;
  // margin-left: 2em;
  width: 100%;
  // margin-top: 0.6em;

  time.date {
    color: #000;
  }

  .loadMore {
    cursor: pointer;
    font-size: 1.2em;
    margin-top: 1em;
    margin-bottom: 1em;
  }

  .row {
    position: relative;
    display: grid;
    grid-template-columns: 3.5em minmax(0, 1fr);
    // align-items: center;

    .itemContainer {
      position: relative;
      margin-left: 0.3em;
    }
  }

  .row.grey {
    .itemContainer,
    time.date {
      background-color: $dark-gray !important;
      color: #fff;
    }

    .rightAligned {
      background-color: $dark-gray !important;
      background: linear-gradient(
        to right,
        $dark-gray-opaque 0%,
        $dark-gray 1em
      ) !important;
    }

    &:nth-child(2n) {
      .itemContainer,
      time.date {
        background-color: #fff !important;
        color: $dark-gray !important;
      }

      .rightAligned {
        background-color: #fff;
        background: linear-gradient(
          to right,
          rgba(255, 255, 255, 0) 0%,
          #fff 1em
        ) !important;
      }
    }
  }

  .row.grosses-haus {
    .itemContainer,
    time.date {
      background-color: $grosses-haus;
      color: #fff;
    }

    .rightAligned {
      background-color: $grosses-haus;
      background: linear-gradient(
        to right,
        $grosses-haus-opaque 0%,
        $grosses-haus 1em
      );
    }

    &:nth-child(2n) {
      .itemContainer,
      time.date {
        background-color: #fff;
        color: $grosses-haus;
      }

      .rightAligned {
        background-color: #fff;
        background: linear-gradient(
          to right,
          rgba(255, 255, 255, 0) 0%,
          #fff 1em
        );
      }
    }
  }

  .row.roter-salon {
    .itemContainer,
    time.date {
      background-color: $roter-salon;
      color: #fff;
    }

    .rightAligned {
      background-color: $roter-salon;
      background: linear-gradient(
        to right,
        $roter-salon-opaque 0%,
        $roter-salon 1em
      );
    }

    &:nth-child(2n) {
      .itemContainer,
      time.date {
        background-color: #fff;
        color: $roter-salon;
      }

      .rightAligned {
        background-color: #fff;
        background: linear-gradient(
          to right,
          rgba(255, 255, 255, 0) 0%,
          #fff 1em
        );
      }
    }
  }

  .row.gruener-salon {
    .itemContainer,
    time.date {
      background-color: $gruener-salon;
      color: #fff;
    }

    .rightAligned {
      background-color: $gruener-salon;
      background: linear-gradient(
        to right,
        $gruener-salon-opaque 0%,
        $gruener-salon 1em
      );
    }

    &:nth-child(2n) {
      .itemContainer,
      time.date {
        background-color: #fff;
        color: $gruener-salon;
      }

      .rightAligned {
        background-color: #fff;
        background: linear-gradient(
          to right,
          rgba(255, 255, 255, 0) 0%,
          #fff 1em
        );
      }
    }
  }

  .row.special {
    .itemContainer,
    time.date {
      background-color: #000;
      color: #fff;
    }

    // .specialInfo {
    //   background-color: #fff;
    //   color: #000;
    // }

    .rightAligned {
      background-color: #000;
      background: linear-gradient(to right, rgba(0, 0, 0, 0) 0%, #000 1em);
    }

    &:nth-child(2n) {
      .itemContainer,
      time.date {
        background-color: #fff;
        color: #000;
      }

      // .specialInfo {
      //   background-color: #000;
      //   color: #fff;
      // }
    }
  }

  .row.festival {
    .itemContainer,
    time.date {
      background-color: $current-day;
      color: #fff;
    }

    .rightAligned {
      background-color: $current-day;
      background: linear-gradient(
        to right,
        $current-day-opaque 0%,
        $current-day 1em
      );
    }

    &:nth-child(2n) {
      .itemContainer,
      time.date {
        background-color: #fff;
        color: $current-day;
      }

      .rightAligned {
        background-color: #fff;
        background: linear-gradient(
          to right,
          rgba(255, 255, 255, 0) 0%,
          #fff 1em
        );
      }
    }
  }

  .specialInfo {
    background-color: #fff;
    color: #000;
  }

  .row:nth-child(2n) .specialInfo {
    background-color: #000;
    color: #fff;
  }
}

@media screen and (max-width: 850px) {
  .EventCalendar {
    width: 100%;

    .loadMore {
      font-size: 1em;
    }

    .row {
      .itemContainer {
        height: 4.9em;
      }
    }
  }
}
