@import "../../styles/colors.scss";
@import "../../styles/fonts.css";

#newsletterIFrame {
  border: none;
}

.Newsletter {
  text-align: left;
  margin: 0 2em;

  form {
    label {
      display: block;
      // display: flex;
      // justify-content: space-between;
      margin-bottom: 1em;

      span {
        display: inline-block;
        font-family: "Egyptienne";
        font-weight: bold;
        font-size: 2em;
        min-width: 10em;
      }

      input,
      input:focus {
        display: inline-block;
        border: 1px solid #000;
        border-radius: 0;
        width: 10em;
        font-size: 1.5em;
        outline: none;
        background-color: #fff;
      }
    }

    button {
      border: none;
      background-color: transparent;
      font-family: "Egyptienne";
      font-weight: bold;
      font-size: 2em;
      cursor: pointer;

      &:hover {
        color: $activeLink;
      }
    }
  }
}

#sendinblue.sib-form,
#sendinblue.sib-form #sib-container,
#sendinblue.sib-form .sib-form-block {
  padding: 0;
  font-family: "Egyptienne";
}

#sendinblue.sib-form .sib-text-form-block {
  font-weight: normal;
  width: 100%;
  max-width: 40rem;
  margin-bottom: 2em;
}

#sendinblue.sib-form .sib-text-form-block p {
  line-height: 1.3;
}

#sendinblue .form__label-row .entry__label {
  max-width: 100%;
  margin-bottom: 1em;
  vertical-align: text-bottom;
}

#sendinblue .form__label-row .entry__label span {
  width: 100%;
  max-width: 20rem;
  font-size: 2em;
  line-height: 1;
}

#sendinblue .form__label-row .entry__choice {
  position: relative;
  margin-left: 20rem;
  width: 100%;
  max-width: 20rem;
  font-family: "Egyptienne";
  font-weight: normal;
  padding-left: 0;
  text-indent: 0;
}

#sendinblue .form__label-row .entry__choice label {
  display: flex;
  align-items: center;
  margin-bottom: 0.8em;
}

#sendinblue.sib-form .checkbox,
.sib-form .radio-button {
  /* display: none; */
  flex: 0 0 auto;
  border: 2px solid #000;
  width: 2em;
  height: 2em;
  border-radius: 0;
  vertical-align: text-top;
}

#sendinblue.sib-form .input_replaced:checked + .checkbox_tick_positive {
  background: #000;
}

/* #sendinblue.sib-form .checkbox_tick_positive:before {
  background-color: transparent;
  border-color: #000;
} */

#sendinblue .form__label-row .entry__choice a,
#sendinblue .form__label-row .entry__choice a:visited {
  text-decoration: none;
  color: #000;
}

#sendinblue .form__label-row .entry__choice a:hover {
  /* text-decoration: none; */
  color: #e31c18;
}

#sendinblue input[type="text"],
#sendinblue input[type="email"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  display: inline-block;
  box-sizing: border-box;
  border: 2px solid #000;
  border-radius: 0;
  box-shadow: none;
  width: 100%;
  max-width: 20rem;
  height: 2em;
  padding: 0.4em 0.5em 0 0.5em;
  vertical-align: baseline;
  line-height: 1.5;
}

#sendinblue .sib-form-block__button {
  font-family: "Egyptienne";
  font-size: 2em;
  font-weight: bold;
  background-color: transparent;
  padding: 0;
}

#sendinblue .sib-form-block__button:hover {
  color: #e31c18;
}

#sendinblue .entry__error--primary {
  color: #e31c18;
  width: 100%;
  max-width: 20rem;
  margin-left: 20rem;
  bottom: 0;
  /* necessary to eliminate the sib margin-top: 6px 
    that's being added inline via js
  */
  margin-top: -6px;
}

#sendinblue .sib-form-message-panel {
  width: 100%;
  max-width: 40rem;
}

#sendinblue .sib-form-message-panel__inner-text {
  line-height: 1;
  padding-top: 0.2em;
}

@media screen and (max-width: 704px) {
  #sendinblue .form__label-row .entry__choice,
  #sendinblue .entry__error--primary {
    margin-left: 0;
  }
}

@media screen and (max-width: 850px) {
  #sendinblue .form__label-row .entry__choice,
  #sendinblue .entry__error--primary {
    margin-left: 0;
  }
}
