@import "../../styles/colors.scss";
@import "../../styles/fonts.css";

.CookieConsent {
  position: absolute;
  z-index: 300;
  display: flex;
  // grid-template-columns: 1fr 20em;
  font-family: "Egyptienne";
  justify-content: space-between;
  color: #fff;
  background-color: #000;
  padding: 2em;
  text-align: left;

  a {
    text-decoration: underline;
  }

  button {
    display: block;
    border: none;
    white-space: nowrap;
    background-color: transparent;
    text-transform: capitalize;
    color: #fff;
    font-family: inherit;
    font-size: 2em;
    cursor: pointer;
    // width: 10em;
    text-align: left;
    margin-left: 1em;

    &::before {
      content: "> ";
    }

    &:hover {
      color: $activeLink;
    }
  }
}

@media screen and (max-width: 850px) {
  .CookieConsent {
    flex-direction: column;
    font-size: 0.8em;
    padding: 1em;
    padding-top: 1.5em;

    & > div:first-child {
      margin-bottom: 2em;
    }

    button {
      margin-left: 0;
      white-space: normal;
      margin-bottom: 1em;
      font-size: 1.3em;
    }
  }
}
