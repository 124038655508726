@import "../../../styles/fonts.css";

time.date {
  font-family: "Egyptienne Extrawide";
  font-weight: bold;
  text-align: center;
  display: block;
  box-sizing: border-box;
  height: 4.1em;
  padding: 0.25em 0 0.5em 0;
  width: 100%;
  padding-left: 0;
  text-transform: uppercase;

  div {
    // font-size: 19px;
    font-size: 1em;
    line-height: 1.1;
    // line-height: 1.2;
  }

  div.weekday {
    line-height: 1;
  }

  div.day {
    font-family: "Egyptienne";
    font-size: 2em;
    line-height: 0.7;
    margin-top: 0.12em;
    font-weight: bold;
  }
}

@media (max-width: 850px) {
  time.date {
    height: 4.9em;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    padding: 0.35em 0 0.2em 0;

    div.day {
      // font-family: "Egyptienne Extra Narrow";
      font-size: 2.65em;
      margin-top: 0.04em;
      // line-height: 1;
    }
  }
}
