/* autoprefixer grid: autoplace */
@import "../../styles/colors.scss";
@import "../../styles/fonts.css";

.PressSection {
  font-family: "Egyptienne";

  .topLinks {
    margin-top: 1em;
    margin-bottom: 0.5em;
    display: flex;
    justify-content: space-between;
  }

  .fileDetails {
    display: grid;
    grid-template-columns: minmax(100px, 1fr) minmax(0, 2fr);
    margin-bottom: 1em;
  }

  .imageContainer {
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 100%;
    background-color: #000;

    .imageWrapper {
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  .fileInfo {
    display: flex;
    flex-direction: column;
    margin-left: 1em;
    justify-content: space-between;

    h3,
    p {
      font-size: 1em;
      margin: 0;
    }

    .download {
      cursor: pointer;
      font-weight: bold;

      &:hover {
        color: $activeLink;
      }
    }
  }

  .fileBox {
    width: 100%;
    height: 0;
    padding-bottom: 50%;
    background-color: #000;
    color: #fff;
    text-align: left;

    span {
      display: block;
      margin: 0.5em 1em;
    }
  }

  @media screen and (max-width: 550px) {
    .topLinks {
      flex-direction: column-reverse;
      gap: 0.5em;
    }

    .pageHeader h1 {
      font-size: 1.6em;
    }
  }
}
