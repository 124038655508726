.Person {
  display: inline-block;
  position: sticky;
  position: -webkit-sticky;
  // position: fixed;
  top: 3em;
  vertical-align: top;
  width: 40%;

  a {
    display: block;
  }

  h1,
  h2 {
    font-size: 2rem;
    margin-bottom: 0;
  }

  p.description {
    margin: 0;
  }

  .linkedShows {
    margin-top: 1em;
  }

  .back {
    margin-top: 1em;
    text-align: right;

    a {
      display: inline-block;
    }
  }
}

@media screen and (max-width: 850px) {
  .Person {
    position: relative;
    height: auto;
    top: 1em;
    // padding: 1em;
    box-sizing: border-box;
    z-index: 200;
    width: 100%;
    font-size: 1rem;
    // background-color: #fff;

    h1 {
      font-size: 1.2rem;
      margin-top: 0.5em;
    }

    a.close {
      position: absolute;
      top: 0;
      right: 0;
    }
    // pointer-events: none;
  }
}
