@import "../../../styles/fonts.css";
@import "../../../styles/colors.scss";

.NotFound {
  font-family: "Egyptienne";
  font-size: 2em;
  font-weight: bold;
  color: $gray;
  display: flex;
  height: 100%;
  min-height: 5em;
  align-items: center;
  justify-content: center;
}
