@import "../../styles/fonts.css";

header.mainHeader {
  font-family: "Goudy";
  padding: 2em 2em 0 2em;
  background-color: #fff;
  min-height: 90px;

  .top {
    position: relative;
    display: flex;
    align-items: center;
    text-align: left;
    justify-content: space-between;
    align-items: flex-end;
    border-bottom: 2px solid black;

    .logo {
      flex: 1 0 auto;
      max-width: 30em;
      align-self: stretch;
      background-image: url("../../assets/varlp_button.svg");
      background-repeat: no-repeat;
      background-size: 100%;
      background-position: bottom left;
      margin-bottom: 1px;
      margin-right: 1em;

      //preload hover image
      // not working unfortunately
      // &::after {
      //   content: "";
      //   position: absolute;
      //   width: 0;
      //   height: 0;
      //   background-image: url("../../assets/varlp_button_rot.svg");
      // }

      &:hover {
        background-image: url("../../assets/varlp_button_rot.svg");
      }
    }

    h1 {
      font-size: 1.4em;
      margin: 0;
    }
  }

  .settings {
    position: relative;
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: baseline;
    line-height: 2;

    .langSearch {
      display: flex;
      flex: 1 1 auto;
      min-width: 350px;
      text-align: left;
    }

    .lang-option {
      flex: 0 0 auto;
    }

    .search {
      flex: 1 0 auto;
    }

    .searchBtn {
      font-family: "Franklin Gothic";
      text-transform: uppercase;
      font-size: 1em;
      margin-left: 0.5em;
    }
  }

  .dropDown {
    position: absolute;
    background-color: #000;
    color: #fff;
    top: 100%;
    height: 0em;
    left: 0;
    width: 100%;
    z-index: 300;
    padding-bottom: 16.7%;
    overflow: auto;

    .innerDropDown {
      position: relative;
      box-sizing: border-box;
      height: 100%;
      width: 100%;
      background-color: #000;
      color: #fff;
      padding: 0.5em 0.8em 0.8em 0.5em;
      font-family: "Egyptienne";
      font-size: 1.1em;
      font-weight: bold;
    }
  }
}

@media (max-width: 1024px) {
  header.mainHeader {
    .top h1 {
      font-size: 1.2em;
    }
  }
}

@media screen and (max-width: 850px) {
  header.mainHeader {
    min-height: 0;
    .top {
      // border-bottom: none;

      h1,
      h3 {
        font-size: 1em;
        margin: 0;
      }

      h3 {
        flex: 0 0 auto;
        text-align: right;
        cursor: pointer;
        margin-bottom: 0;
        line-height: 0;
      }
    }

    .dropDown {
      position: relative;
      height: 10em;
      left: auto;
      width: 100%;
      background-color: #fff;

      .results,
      .innerDropDown {
        height: 100%;
        background-color: #fff;
        color: #000;
      }
    }

    .mobileMenu {
      position: absolute;
      z-index: 250;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      padding: 1em;
      color: #fff;
      // width: 10em;
      transform: translateX(100%);
      background-color: #000;
      transition: transform 0.5s cubic-bezier(0.26, 0.86, 0.44, 0.985);

      .top {
        border-bottom: 2px solid #fff;
        color: #fff;

        .logo {
          background-image: url("../../assets/varlp_button_weiß.svg");
        }
      }

      .settings {
        display: block;
        text-align: left;
        margin-top: 1em;
      }

      .searchBtn {
        margin-left: 0;
      }

      &.open {
        .searchBtn {
          display: none;
        }
      }
    }

    &.menuOpen {
      .mobileMenu {
        transform: translateX(0);
      }
    }
  }
}
