/* autoprefixer grid: autoplace */
@import "../../styles/fonts.css";
@import "../../styles/colors.scss";

.pageTabs {
  font-family: "Egyptienne Extrawide";
  font-size: 1.8em;
  font-weight: bold;
  width: 100%;
  border-bottom: 3px solid black;

  padding: 1em 0;

  a {
    &.selected .title {
      color: black;
      text-decoration: underline;
      text-decoration-thickness: 3px;
    }

    &.selected:hover .title {
      color: $activeLink;
    }

    &:last-child .separator {
      display: none;
    }
  }
}

.linkGrid {
  font-family: "Egyptienne Extrawide";
  display: flex;
  flex-direction: column;
  // padding: 1em;
  // grid-template-columns: 1fr;
  // justify-items: flex-start;
  text-align: left;
  margin-top: 2em;

  .productionLink {
    width: 100%;
    border-bottom: 3px solid black;
    padding-bottom: 2em;
    margin-bottom: 2em;

    &:last-child {
      border: none;
    }
  }

  p {
    font-family: "Egyptienne";
    font-size: 1.2em;
    margin: 0;
    margin-top: 0.2em;

    .detail:first-child .separator {
      display: none;
    }
  }

  h3 {
    margin: 0;
    font-size: 1.8em;
    line-height: 1;
    // margin-top: 0.2em;
    .subtitle {
      font-family: "Egyptienne Narrow", "Roboto Slab";
      font-weight: normal;
    }
  }

  .pressVersion {
    display: grid;
    grid-template-columns: minmax(0, 1fr) 10em;
    grid-template-rows: auto;
    align-items: baseline;
  }

  .presstext {
    border-bottom: 3px solid black;
    padding-bottom: 2em;
    margin-bottom: 2em;

    p:first-child {
      margin-bottom: 1em;
    }
  }
}

@media screen and (max-width: 850px) {
  .linkGrid {
    .pressVersion {
      display: block;

      a {
        display: block;
        margin-top: 0.5em;
      }
    }

    p {
      .detail {
        display: block;
        .separator {
          display: none;
        }
      }
    }
  }
}
