/* autoprefixer grid: autoplace */
@import "../../styles/colors.scss";
@import "../../styles/fonts.css";

.LepoNav {
  border-bottom: 2px solid #000;

  h2 {
    text-align: center;
  }

  .venueSwitch {
    position: relative;
    display: grid;
    grid-template-columns: 2em 1fr 2em;
    align-items: center;
    height: 100%;
    overflow: hidden;

    .arrow {
      font-family: "Egyptienne";
      font-weight: bold;
      font-size: 2em;
      margin: 0;
      text-align: center;
      // padding-top: 0.2em;
      line-height: 1;
      color: #000;
      background-color: transparent;
      z-index: 100;

      &.disabled {
        opacity: 0.3;
        pointer-events: none;
      }
    }

    .buttons {
      display: grid;
      // transition: transform 0.3s cubic-bezier(0.26, 0.86, 0.44, 0.985);
      transform: translateX(0%);
      pointer-events: none;
    }

    &.size-2 {
      .buttons {
        grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
        width: 200%;
      }

      &.active-1 .buttons {
        transform: translateX(-50%);
      }
    }

    &.size-3 {
      .buttons {
        grid-template-columns: repeat(3, minmax(0, 1fr));
        width: 300%;
      }

      &.active-1 .buttons {
        transform: translateX(-33.33%);
      }

      &.active-2 .buttons {
        transform: translateX(-66.66%);
      }
    }

    &.size-4 {
      .buttons {
        grid-template-columns: repeat(4, minmax(0, 1fr));
        width: 400%;
      }

      &.active-1 .buttons {
        transform: translateX(-25%);
      }

      &.active-2 .buttons {
        transform: translateX(-50%);
      }

      &.active-3 .buttons {
        transform: translateX(-75%);
      }
    }
  }
}

@media (max-width: 850px) {
  .LepoNav {
    .venueSwitch {
      // position: relative;
      // grid-template-columns: 3.5em 1fr;
    }

    h2 {
      opacity: 0;

      &.active {
        opacity: 1;
      }
    }
  }
}
