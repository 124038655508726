@import "../../styles/fonts.css";

footer {
  position: relative;
  font-family: "Franklin Gothic";
  // border: 1px solid black;
  padding: 1em 0;

  a {
    display: inline-block;
    text-transform: uppercase;
    // font-weight: 500;
    margin-right: 1em;
  }
}
