@import "../../styles/fonts.css";

.Gallery {
  height: 100%;

  li {
    position: relative;
    height: 100%;

    img {
      position: absolute;
      height: 100%;
      width: auto;
      left: 50%;
      transform: translateX(-50%);
    }

    // &:first-child {
    .galleryImgContainer {
      position: absolute;
      height: 100%;
      width: 100%;
      box-sizing: border-box;
      overflow: hidden;
      // bottom: -1px;
      left: 0;
      right: 0;
      // height: 100%;
      box-sizing: border-box;
    }

    // img,
    .VideoPlayer {
      position: absolute;
      top: 0;
      left: 0;
      top: 50%;
      width: 100%;
      max-height: none;
      transform: translateY(-50%);
    }
    // }
  }

  .copyright {
    font-family: "Franklin Gothic";
    font-size: 0.8em;
    position: absolute;
    right: 0.5em;
    bottom: 0.5em;
    color: #fff;
  }

  .galleryDots {
    position: absolute;
    bottom: 1em;
    text-align: center;
    width: 100%;

    button {
      border: none;
      width: 12px;
      height: 12px;
      border-radius: 50%;
      padding: 0;
      border: 1px solid #000;
      background-color: rgba(255, 255, 255, 0.5);
      margin: 0 5px;

      &:disabled {
        cursor: default;
        background-color: #fff;
      }
    }
  }
}
