@import "../../styles/colors.scss";

.subMenu {
  font-size: 1.2em;
  line-height: 1;
  text-align: right;
  padding-bottom: 0.3em;

  ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    list-style-type: none;
    // flex-direction: row-reverse;
    margin: 0;
    padding: 0;
  }

  a {
    font-size: 0.9em;
    font-weight: bold;
    color: #000;
    text-decoration: none;
    margin-left: 0.5em;
    white-space: nowrap;
    // text-transform: uppercase;

    &.active {
      color: $activeLink;
    }

    &.menuPoint {
      margin-right: 0.5em;
    }
  }

  button {
    outline: none;
    border: none;
    background-color: transparent;
    font-size: inherit;
    text-transform: uppercase;
    cursor: pointer;
  }
}

@media screen and (max-width: 850px) {
  .subMenu {
    font-size: 0.8em;
    text-align: left;
    height: auto;
    padding-left: 0.5em;
    // max-height: 100vh;
    overflow: hidden;
    animation: openMenu 2s cubic-bezier(0.26, 0.86, 0.44, 0.985);

    ul {
      display: block;
      padding-left: 0;
      // margin: 0;

      li {
        display: block !important;
      }

      a {
        margin-left: 0;
      }
    }
  }

  @keyframes openMenu {
    0% {
      max-height: 0;
      // opacity: 0;
    }
    100% {
      // opacity: 1;
      max-height: 50vh;
    }
  }
}
