/* autoprefixer grid: autoplace */
@import "../../styles/colors.scss";
@import "../../styles/fonts.css";

.leporello {
  a {
    color: inherit;
    text-decoration: none;
    display: inline;

    &:hover {
      color: inherit;
    }
  }

  h3 {
    font-size: 1em;
    // display: inline;
  }

  h2.month {
    font-family: "Franklin Gothic Condensed";
    font-weight: normal;
    text-transform: uppercase;
    text-align: center;
    font-size: 2.5em;
    color: #fff;
    background-color: #000;
    padding: 0.6rem 0;
    margin: 0;

    div {
      display: inline-block;
    }
    .side-info {
      font-size: 0.37em;
      max-width: 200px;
      text-transform: none;
      margin-left: 1em;

      span {
        text-transform: uppercase;
      }
    }
  }

  section {
    display: flex;

    h3,
    h4 {
      padding: 0;
      margin: 0;
    }

    aside {
      flex: 0 0 auto;

      time {
        display: block;
        height: 3em;
        width: 2em;
        padding: 0.3em;
        color: #fff;
        text-transform: uppercase;
        div {
          font-size: 0.7em;
        }

        div.day {
          font-size: 1.2em;
          font-weight: bold;
        }
      }

      &.left {
        margin-right: 0.2em;
        time {
          background-color: $roter-salon;

          &:nth-child(2n) {
            color: $roter-salon;
            background-color: #fff;
          }
        }
      }

      &.right {
        time {
          background-color: $prater;

          &:nth-child(2n) {
            color: $prater;
            background-color: #fff;
          }
        }
      }
    }

    .venueContainer {
      flex: 1 0 auto;

      .allVenues {
        display: flex;

        ol {
          flex: 1 0 auto;
          list-style-type: none;
          margin: 0;
          padding: 0;
          margin-right: 0.2em;
        }
      }
    }

    .lepoItem {
      text-align: left;
      height: 3em;
      padding: 0.3em 1em;
      color: #fff;
    }

    .grosses-haus .lepoItem {
      background-color: $grosses-haus;

      &:nth-child(2n) {
        background-color: #fff;
        color: $grosses-haus;
      }
    }

    .prater .lepoItem {
      background-color: $prater;

      &:nth-child(2n) {
        background-color: #fff;
        color: $prater;
      }
    }

    .roter-salon .lepoItem {
      background-color: $roter-salon;

      &:nth-child(2n) {
        background-color: #fff;
        color: $roter-salon;
      }
    }
  }

  .event {
    display: grid;
    grid-template-columns: 2em 1fr;
    padding: 0.5em 1em;

    &:nth-child(2n) {
      background-color: #000;
      color: #fff;
    }

    a {
      &:hover {
        color: red;
      }
    }
  }
}

// @media only screen and (max-width: 800px) {
//   .leporello {
//     width: 100%;
//     position: relative;

//     nav {
//       h2 {
//         border-width: 2px;
//         border-style: solid;
//         border-radius: 1em;

//         &.grosses-haus {
//           border-color: $grosses-haus;

//           &.active {
//             color: #fff;
//             background-color: $grosses-haus;
//           }
//         }
//         &.roter-salon {
//           border-color: $roter-salon;

//           &.active {
//             color: #fff;
//             background-color: $roter-salon;
//           }
//         }
//         &.prater {
//           border-color: $prater;

//           &.active {
//             color: #fff;
//             background-color: $prater;
//           }
//         }
//       }
//     }

//     section {
//       // display: block;
//       width: 100%;
//       // overflow: hidden;
//       // display: block;
//       position: relative;

//       // grid-template-columns: 3em 1fr;
//       .venueContainer {
//         position: relative;
//         display: block;
//         left: 0;
//         overflow: hidden;
//         //
//         .allVenues {
//           display: flex;
//           width: 300%;

//           // display: inline-block;

//           .venueList {
//             display: block;
//             margin: 0;
//             flex: 1;
//             transition: transform 0.3s cubic-bezier(0.26, 0.86, 0.44, 0.985);
//             transform: translateX(0%);
//           }
//           // position: absolute;
//         }
//       }

//       aside {
//         display: inline-block;

//         .date {
//           transition: background-color 0.3s linear;
//         }
//       }

//       &.grosses-haus {
//         aside.left .date {
//           background-color: $grosses-haus;

//           &:nth-child(2n) {
//             background-color: #fff;
//             color: $grosses-haus;
//           }
//         }

//         .allVenues .venueList {
//           transform: translateX(-100%);
//         }
//       }

//       &.roter-salon aside.left .date {
//         background-color: $roter-salon;

//         &:nth-child(2n) {
//           background-color: #fff;
//           color: $roter-salon;
//         }
//       }

//       &.prater {
//         aside.left .date {
//           background-color: $prater;

//           &:nth-child(2n) {
//             background-color: #fff;
//             color: $prater;
//           }
//         }
//         .allVenues .venueList {
//           transform: translateX(-200%);
//         }
//       }

//       aside.right {
//         display: none;
//       }

//       // .venueList {
//       //   flex: 0;
//       //   width: 100%;
//       // }

//       // .venueList.active {
//       //   flex: 1;
//       //   display: block;
//       // }
//     }
//   }
// }

@media screen and (max-width: 850px) {
  .leporello {
    h2.month {
      div {
        display: block;
        text-align: center;
      }

      .side-info {
        font-size: 0.33em;
        max-width: 10000px;
        margin-left: 0;
        margin-top: -0.3em;

        span {
          text-transform: none;
        }
      }
    }
  }
}
