@import "../../styles/colors.scss";
@import "../../styles/fonts.css";

.page {
  margin-bottom: 2em;

  .textContent {
    text-align: left;
    padding: 2em 2px;
    column-count: 2;
    column-gap: 2em;
    font-family: "Egyptienne", "Roboto Slab";
    line-height: 1.2rem;

    &.nocols {
      column-count: 1;
      padding: 1.5em 0;
      font-size: 1.2em;
      line-height: normal;
      font-family: "Egyptienne Narrow", "Roboto Slab";

      p:last-child {
        margin-bottom: 0;
      }
    }

    a {
      text-decoration: underline !important;
    }

    a[href^="tel:"] {
      text-decoration: none !important;
    }

    h2 {
      font-size: 2rem;
    }

    h2,
    h3 {
      font-family: "Egyptienne Extrawide";
      break-after: avoid-column;
      line-height: 2.4rem;
      margin: 1.2rem 0;

      &:first-of-type {
        margin-top: 0;
      }
    }

    div {
      break-inside: avoid-column;
    }

    p {
      margin: 1.2rem 0;
      break-inside: avoid;
    }

    p:first-child {
      margin-top: 0;
    }

    h3 {
      line-height: 1.2rem;
    }

    br {
      line-height: 1.2rem;
    }

    b {
      line-height: 0.95rem;
    }

    address {
      font-style: normal;
    }

    address:first-child {
      column-span: all;
    }

    address:first-child + p {
      display: block;
      column-span: all;
      padding-bottom: 2em;
      margin-bottom: 2em;
      border-bottom: 3px solid black;
    }

    address:first-child + p:empty {
      height: 0;
      padding: 0;
    }
  }

  .MediaCredits {
    font-size: 1.2em;
    line-height: 1.23;
    font-family: "Egyptienne Narrow", "Roboto Slab";
    border-top: 3px solid black;
    padding-top: 1.5em;
    padding-bottom: 1.5em;
  }

  .topImage {
    position: relative;
    width: 100%;
    text-align: center;
    margin-bottom: 0.3em;
  }

  .titleSection {
    display: block;
    z-index: 100;
    font-family: "Goudy", "Roboto Slab";
    color: #fff;
    text-align: left;
    padding: 1em 1.5em;
    background-color: #000;

    h1,
    h2 {
      margin: 0;
    }

    h1 {
      line-height: 1;

      .divider:last-child {
        display: none;
      }
    }
  }

  audio {
    width: 100%;
  }

  // .audioContainer {
  //   position: relative;
  //   width: 100%;
  //   margin-top: 2em;

  // }

  .calendarWrapper:not(.audio) {
    margin-top: 2em;
  }

  .calendarWrapper.audio {
    margin-bottom: 2em;
  }
}

@media screen and (max-width: 850px) {
  .page {
    .textContent {
      column-count: 1;
      padding: 1em 0;

      &.nocols {
        font-size: 1em;
      }
    }

    .titleSection {
      font-size: 0.7em;
    }

    .MediaCredits {
      font-size: 1em;
    }
  }
}
