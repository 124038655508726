/* autoprefixer grid: autoplace */
@import "../../styles/colors.scss";

.logos {
  display: grid;
  grid-template-columns: repeat(5, minmax(0, 1fr));
  gap: 12px;
  gap: 0.8vw;
  list-style-type: none;
  padding: 0;

  li {
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 100%;
    // background-color: $background;
  }

  img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
    max-width: 100%;
    max-height: 100%;
  }
}
