@import "./styles/colors.scss";

$max-width: 1366px;

.App {
  position: relative;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  text-align: center;
  height: 100%;
  margin: 0 auto;
  color: #000;
  padding-bottom: 0;
  overflow: hidden;

  .mainHeader {
    flex: 0 0 auto;
    padding: 2em;
    padding-bottom: 0;
    box-sizing: border-box;
    width: 100%;
    max-width: calc(1366px + 4em);
    margin: 0 auto;
  }

  .pageHeader {
    max-width: $max-width;
    box-sizing: border-box;
    // margin: 0 auto;
  }

  .lepoImg {
    display: block;
    width: 100%;
    max-width: $max-width;
    margin: 0;
    padding: 0;
  }

  #scrollable {
    position: relative;
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    align-items: center;
    overflow: auto;
    height: 100%;
    padding: 2em;
    padding-top: 0;

    .Programme {
      margin-bottom: 10em;
      margin-bottom: 30vh;
    }
  }

  #content {
    display: flex;
    flex: 1 0 auto;
    flex-direction: column;
    justify-content: space-between;
    box-sizing: border-box;
    width: 100%;
    max-width: $max-width;
    // min-height: 100%;
    text-align: left;
  }

  footer {
    justify-self: flex-end;
  }

  a {
    color: inherit;
    text-decoration: none;
  }

  a:not(.underline):hover,
  a:not(.underline).active {
    color: $activeLink;
  }

  a.underline:hover,
  a.underline.active {
    text-decoration: underline;
  }

  .clickable {
    cursor: pointer;
  }

  .nobreak {
    white-space: nowrap;
  }

  img {
    // -webkit-filter: grayscale(100%);
    // filter: grayscale(100%);
  }

  .screenreader {
    position: absolute;
    left: -999999px;
  }
}

@media screen and (max-width: 850px) {
  .App {
    .mainHeader,
    #scrollable {
      padding: 1em;
    }

    .mainHeader {
      padding-bottom: 0;
      margin-bottom: 1em;
    }

    #scrollable {
      padding-top: 0;
    }

    .imgContainer {
      display: none;
      padding-left: 0;
      padding-right: 0;
    }

    *[role="button"]:focus,
    button:focus {
      outline: 0;
    }
  }
}
