/* autoprefixer grid: autoplace */
@import "../../styles/colors.scss";
@import "../../styles/fonts.css";

.imgContainer {
  width: 100%;
  background-color: #fff;
  z-index: 100;
  padding: 1em 2em;
  padding-top: 0.5em;
  box-sizing: border-box;
  max-width: 1366px;

  .headerImg {
    width: 100%;
  }
}

.pageHeader {
  display: block;
  // position: sticky;
  // position: -webkit-sticky;
  align-self: flex-start;
  top: 0;
  width: 100%;
  text-align: left;
  padding: 0 1rem;
  margin: 0 auto;
  // box-sizing: border-box;
  background-color: $navbg;
  z-index: 100;

  &.lepo {
    width: calc(100% - 4rem);
    padding: 0;
    background-color: transparent;
  }

  h1,
  h2 {
    display: inline-block;
    font-family: "Goudy";
    font-size: 2em;
    margin: 0;
    padding-top: 0.1em;
    line-height: 1.5;
    color: #fff;
  }

  &.lepo .LepoNav {
    display: grid;
    grid-template-columns: 3.5em repeat(3, minmax(0, 1fr)) 3.5em;
    align-items: center;
    padding: 0.5em 0;

    h2 {
      flex-basis: 0%;
      color: #000;
      line-height: 1;
    }
  }

  .banner {
    position: relative;
    background-color: #000;
    height: 0;
    width: 100%;
    padding-bottom: 16.7%;

    h1 {
      color: #fff;
      width: 100%;
      height: 100%;
      margin: 1em;
      padding: 0;
      line-height: 1;
    }

    .bannerTextContainer {
      position: absolute;
      width: 100%;
      height: 100%;
      background-color: red;
    }
  }

  img {
    width: 100%;
  }

  .Loader {
    img {
      width: auto;
    }
  }
}

// @media (max-width: 1024px) {
//   .pageHeader {
//     // font-size: 0.8em;
//   }
// }

@media (max-width: 850px) {
  .pageHeader {
    &.lepo {
      position: sticky;
      position: -webkit-sticky;
      width: calc(100% - 2rem);
      margin: 0 auto;
    }
    // padding: 0 1em;

    .imgContainer {
      display: none;
    }

    &.lepo {
      //   // margin: 0 5em;
      //   width: calc(100% - 2rem);
      //   padding: 0;

      .LepoNav {
        // position: relative;
        grid-template-columns: 1fr;
        // padding: 0 0.5em;
      }
    }
  }
}

@media only screen and (max-device-width: 640px) and (orientation: landscape) {
  .imgContainer {
    display: none;
  }
}
