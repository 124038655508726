.MediaCredits {
  &:empty {
    display: none;
  }
  .credit {
    display: inline;

    span > * {
      display: inline;
    }
  }

  .credit:first-child {
    .separator {
      display: none;
    }
  }
}
