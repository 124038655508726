@import "../../styles/colors.scss";
@import "../../styles/fonts.css";

.People {
  position: relative;
  font-family: "Egyptienne";
  will-change: transform;
  font-size: 1.2em;
  padding-bottom: 5em;
  // display: grid;
  // grid-template-columns: minmax(0, 1.5fr) minmax(0, 1fr);
  h2 {
    font-size: 2rem;
  }

  h2 u {
    text-decoration: none;
  }

  .list {
    display: inline-block;
    width: 60%;
    padding-right: 2em;
    box-sizing: border-box;
  }

  .startLetter {
    font-weight: bold;
  }

  .personLink > span.name {
    white-space: nowrap;
  }

  .personLink:last-child {
    .comma {
      display: none;
    }
  }

  .functionSelection {
    .dot:last-child {
      display: none;
    }
  }

  .functionCategory {
    display: inline-block;
    margin: 0.5em;
    cursor: pointer;

    &.active {
      color: $activeLink;
    }
  }
}

@media screen and (max-width: 850px) {
  .People {
    h1,
    h2 {
      font-size: 1.2rem;
    }

    .list {
      font-size: 1rem;
      width: 100%;
    }

    .Person + .list {
      display: none;
    }
  }
}
