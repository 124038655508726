@import "../../../styles/fonts.css";

.LepoItem {
  // position: relative;
  // position: absolute;
  text-align: left;
  height: 100%;
  box-sizing: border-box;
  // left: 0;
  // right: 0;
  // width: auto;
  padding: 7px 0.5em;
  line-height: 17px;
  font-family: "Egyptienne Narrow", "Roboto Slab";

  .text {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .venueInfo {
    text-transform: uppercase;
  }

  time {
    font-weight: normal;
  }

  h3 {
    display: inline;
    width: 100%;
    // white-space: nowrap;
    // overflow: hidden;
    // text-overflow: ellipsis;
    // display: inline;
    // font-family: "Egyptienne";
    font-size: 1em;
    margin: 0;
    // margin-right: 0.5em;
  }

  .otherInfo {
    display: inline;
    // font-family: "Egyptienne Narrow";

    span {
      display: inline;
    }

    .subtitle {
      // display: -webkit-box;
      // -webkit-line-clamp: 1;
      // -webkit-box-orient: vertical;
      // overflow: hidden;
      // text-overflow: ellipsis;
    }
  }

  .rightAligned {
    position: absolute;
    font-family: "Franklin Gothic";
    bottom: 0.4em;
    right: 0.5em;
    padding-left: 1.5em;
    white-space: nowrap;
    justify-content: flex-end;

    .specialInfo {
      display: inline-block;
      text-transform: uppercase;
      font-size: 0.9em;
      line-height: 1;
      padding: 0.1em 0.4em 0 0.4em;
      // transform: translatey(1px);
    }

    .ticketLink {
      display: inline-block;
      font-size: 0.9em;
      line-height: 0.7;
      margin-left: 0.5em;
      margin-bottom: 0.15em;

      &.disabled .tickets {
        text-decoration: line-through;
        cursor: not-allowed;
      }
    }
  }
}

@media (max-width: 850px) {
  .LepoItem {
    padding: 5.5px 0.5em;
    line-height: 17px;
    .text {
      -webkit-line-clamp: 3;
    }

    .rightAligned {
      bottom: 0.3em;
    }
  }
}
