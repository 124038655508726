@import "../../../styles/fonts.css";

.lang-option {
  font-family: "Franklin Gothic";
  text-transform: uppercase;
  vertical-align: baseline;
  // margin-top: 0.2em;

  // &::after {
  //   content: "/";
  // }

  // &:last-child::after {
  //   display: none;
  // }
}
