@import "../../styles/fonts.css";

.search {
  // position: relative;
  display: inline-block;
  vertical-align: baseline;
  width: 3.55em;
  margin-left: 0.5em;
  color: #fff;

  input[type="text"],
  input:focus {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    outline: none;
    border: none;
    // background-color: #000;
    // color: #fff;
    width: 100%;
    font-size: 1em;
    padding: 0;
    font-family: "Franklin Gothic";
    box-sizing: border-box;
    cursor: pointer;
  }

  input::placeholder {
    text-transform: uppercase;
    color: #000;
  }

  input:focus::placeholder {
    color: transparent;
  }

  .results {
    position: relative;
    // display: inline-block;
    // top: 100%;
    height: 100%;
    // left: 0;
    // width: calc(100% + 2.5rem);
    // z-index: 300;
    background-color: #000;
    color: #fff;
    padding: 0.5em 0.8em 0.8em 0.5em;

    font-family: "Egyptienne";
    font-size: 1.1em;
    font-weight: bold;
    // overflow: auto;

    .loader,
    .searchResultWrapper > span,
    a.searchResult {
      display: inline;
      vertical-align: baseline;
      font-size: 0.8em;
      line-height: 1;
      text-align: left;
      margin-top: 0.5em;
    }
  }
}

// necessary to override a from header
#mainNav ul.mainMenu a.searchResult {
  color: #fff;
}

@media screen and (max-width: 850px) {
  .search {
    position: relative;
    display: block;
    width: 100%;
    margin-left: 0;
    color: #000;

    input[type="text"],
    input:focus {
      background-color: #000;
      color: #fff;
      caret-color: #fff;
      // border: 1px solid red;
    }

    input::placeholder {
      color: #fff;
    }

    .results {
      // width: 100%;
      background-color: #fff;
    }
  }
}
