@import "../../styles/colors.scss";

.MonthSelector {
  button {
    border: none;
    background-color: transparent;
    padding: 0;
    font-size: 1em;
    font-family: "Franklin Gothic";
    text-transform: uppercase;
    vertical-align: baseline;
    margin-right: 0.5em;
    cursor: pointer;

    &:hover,
    &.active {
      color: $activeLink;
    }
  }

  .innerDropDown a {
    font-family: "Egyptienne";
    font-size: 0.8em;
    font-weight: bold;
    text-transform: none;
  }
}

@media screen and (max-width: 850px) {
  .MonthSelector {
    position: relative;

    button {
      color: #fff;
    }
  }
}
